import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import logo from '../img/edelweiss.svg'

const Hero = () => (
    <StaticQuery
      query={graphql`
        query {
          wordpressSiteMetadata {
            name
            description
          }
        }
      `}
      render={data => (
        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container has-text-centered">
                <h1 className="title">
                    {data.wordpressSiteMetadata.name}
                </h1>
                <h2 className="subtitle is-uppercase">
                    {data.wordpressSiteMetadata.description}
                </h2>
                </div>
            </div>
        </section>    
        )}
    />
)

export default Hero
